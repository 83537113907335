@charset "utf-8";

@use 'sass:color';
@use 'sass:map';

$foundries-green: hsl(163, 57.4%, 46.1%);
$foundries-dark-blue: hsl(231, 94%, 13%);
$foundries-gray: hsl(220, 20%, 85.3%);
$foundries-accent: hsl(28, 91.5%, 54.1%);

$accent: $foundries-accent;

$foundries-colors: (
  'foundries-green': #{$foundries-green},
  'foundries-dark-blue': #{$foundries-dark-blue},
  'foundries-gray': #{$foundries-gray},
  'foundries-accent': #{$foundries-accent},
);

$blue: #1e90ff;
$cyan: #17a2b8;
$green: #28a745;
$red: #ff4500;
$yellow: #ffa500;

$black: hsl(0, 0%, 0%);
$white: hsl(0, 0%, 100%);
$text: $black;

$grey-900: hsl(208, 10.5%, 23.5%);
$grey-800: hsl(209, 10.5%, 33.5%);
$grey-700: hsl(210, 7%, 55%);
$grey-600: hsl(212, 9%, 65%);
$grey-500: hsl(215, 10%, 68%);
$grey-400: hsl(215, 15%, 80%);
$grey-300: hsl(220, 20%, 85.3%);
$grey-200: hsl(225, 21%, 91%);
$grey-100: hsl(225, 22%, 96%);

$grey-shades: (
  '900': #{$grey-900},
  '800': #{$grey-800},
  '700': #{$grey-700},
  '600': #{$grey-600},
  '500': #{$grey-500},
  '400': #{$grey-400},
  '300': #{$grey-300},
  '200': #{$grey-200},
  '100': #{$grey-100},
);

$dark-green: color.adjust($green, $lightness: -15%);
$grey: $foundries-gray;

$primary: $foundries-dark-blue;
$purple: $primary;

$body-background-color: $grey-100;

$family-primary: 'Roboto Flex', sans-serif;
$family-sans-serif: 'Roboto Flex', sans-serif;
$family-monospace: 'Roboto Mono', monospace;
$family-body: $family-primary;

$widescreen-enabled: true;
$fullhd-enabled: true;

$radius: 3px;
$box-radius: $radius;
$box-padding: 16px;

$navbar-dropdown-radius: $radius;
$navbar-dropdown-boxed-radius: $radius;
$navbar-height: 4rem;
$navbar-item-width: 3rem;

$section-padding: 24px 0 24px 0;

$gap: 16px;
$column-gap: 0.5rem;

$body-size: 16px;
$body-line-height: 150%;

$letter-spacing: 1%;

$size-x-huge: 5.5rem;
$size-huge: 4.125rem;
$size-xxxx-large: 3.375rem;
$size-xxx-large: 2.625rem;
$size-xx-large: 2.25rem;
$size-x-large: 1.75rem;
$size-largest: 1.5rem;
$size-larger: 1.3125rem;
$size-large: 1.1875rem;
$size-medium: 1.0625rem;
$size-normal: 1rem;
$size-small: 0.875rem;
$size-smaller: 0.75rem;

$text-dimensions: (
  'x-huge': #{$size-x-huge},
  'huge': 4.125rem,
  'xxxx-large': 3.375rem,
  'xxx-large': 2.625rem,
  'xx-large': 2.25rem,
  'x-large': 1.75rem,
  'largest': 1.5rem,
  'larger': 1.3125rem,
  'large': 1.1875rem,
  'medium': 1.0625rem,
  'normal': 1rem,
  'small': 0.875rem,
  'smaller': 0.75rem,
);

$modal-card-body-padding: 1.5rem;
$modal-card-head-padding: 1.5rem;

$notification-radius: 0px;

$divider-margin-size: 2rem;
$divider-margin-inner-size: 0.5rem;

// $fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
$fa-font-path: '~@awesome.me/kit-8b90e42ad7/icons/webfonts';

$breadcrumb-item-padding-horizontal: 0.5rem;

@import '~bulma/bulma.sass';

$steps-completed-color: $foundries-green;
$steps-active-color: color.adjust($foundries-green, $lightness: 25%);

$ph-color: $grey-lighter;
$ph-bg: $white;
$ph-border: none;
