@import '../../styles/variables.scss';

.app-box {
  .box-title-rule {
    height: 1px;
    margin-top: 8px;
    background-color: $grey-lighter;
  }

  .has-no-box-shadow {
    > .box {
      box-shadow: none;
    }
  }
}
